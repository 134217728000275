import ProfileShowCSS from './ProfileShow.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faCircleExclamation, faPhone, faStar, faShareNodes} from '@fortawesome/free-solid-svg-icons'
import {faWhatsapp} from '@fortawesome/free-brands-svg-icons'
import useTitle from '../../hooks/useTitle'
import {
    selectUserById,
    useGetUsersQuery,
    useUpdateRemoveUserFollowingMutation, useUpdateUserClicksMutation,
    useUpdateUserFollowingMutation
} from '../../features/user/usersApiSlice'
import PulseLoader from 'react-spinners/PulseLoader'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {useSelector} from 'react-redux'
import React, {useEffect, useState} from 'react'
import ProfileStories from "../UserStories/ProfileStories"
import dayjs from 'dayjs'
import {calculateAge, haversineDistance} from '../../common/utils'
import BasicTabs from './TabSystem'
import CheckIcon from '@mui/icons-material/Check'
import FullScreenModal from './FullScreenModal'

const ProfileShow = ({loggedUser}) => {

    useTitle('CamNextDoor: My Profile')

    const navigate = useNavigate()

    const { state } = useLocation()

    const phoneNumber = "+381621333623"

    const {
        data: users,
        isLoading,
        isSuccess,
        isError,
        error
    } = useGetUsersQuery('usersList', {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [UseUpdateUserClicks] = useUpdateUserClicksMutation()

    const [UseUpdateRemoveUserFollowing,{
        isUploadRemoveLoading,
    }] = useUpdateRemoveUserFollowingMutation()

    const [UseUpdateUserFollowing,{
        isUploadLoading,
    }] = useUpdateUserFollowingMutation()

    const {id: follower} = useParams()

    const [redirectOpened, setRedirectOpened] = useState(false)
    const [reportOpened, setReportOpened] = useState(false)
    const [isFollowing ,setIsFollowing]= useState(loggedUser !== null && loggedUser?.following?.includes(follower))
    const [fullscreenOpened, setFullscreenOpened] = useState(false)

    const [age, setAge] = useState(0)

    const { id } = useParams()

    const paramUser = useSelector(state => selectUserById(state, id))

    useEffect(() => {
        if(paramUser){
            setAge(calculateAge(dayjs(paramUser?.birthDate)))
        }
    }, [paramUser])


    useEffect(() => {
        UseUpdateUserClicks({id: id});
    }, []);

    const phoneNumberWhatsapp = '+381621333623'
    const predefinedMessage = `Hey ${paramUser?.username} I found you on CamNextDoor and like your profile!`
    function openWhatsApp(number, message) {
        const encodedMessage = encodeURIComponent(message)
        window.location.href = `whatsapp://send?phone=${number}&text=${encodedMessage}`
    }

    const handleFollowClicked = async () => {
        if (loggedUser) {
            if (isFollowing) {
                await UseUpdateRemoveUserFollowing({id: loggedUser._id, followId: follower})
                setIsFollowing(false)
            } else {
                await UseUpdateUserFollowing({id: loggedUser._id, followId: follower})
                setIsFollowing(true)

            }
            setIsFollowing(!isFollowing)
        }
        else{
            setRedirectOpened(true)
        }
    }

    const handleReport = () => {
        if(loggedUser) setReportOpened(true)
        else setRedirectOpened(true)
    }

    const handleShare = async () => {

        if (navigator.share) {
            try {
                await navigator.share({
                    title: `Profile of ${paramUser?.username}`,
                    url: window.location.href // or construct the URL as needed
                })
                console.log('Profile shared successfully')
            } catch (error) {
                console.error('Error sharing profile:', error)
            }
        } else {
            console.log('Web Share API is not supported in your browser.')
            // Fallback logic or inform the user that sharing isn't available.
        }
    }

    let content

    if (isLoading || !paramUser) content = <PulseLoader color={'#FFF'} />

    if (isError) {
        content = <p className='errmsg'>{error?.data?.message}</p>
    }

    if (isSuccess) {

        content = (
            <>
                <div className={ProfileShowCSS['profile-image']}>
                    <div className={ProfileShowCSS['profile-heading']}>
                        <div onClick={() => setFullscreenOpened(true)}>
                            <img src={paramUser?.profileImage} alt='profile-img' className={`${paramUser?.verified ? ProfileShowCSS['verified'] : ''}`}/>
                            <span>
                                <CheckIcon />
                            </span>
                        </div>
                        <div>
                            <h2 style={{fontWeight: 'bold', fontSize: '18px'}}>{paramUser?.username} , {age}</h2>
                            <div className={ProfileShowCSS['location-content']}>
                                <span className={ProfileShowCSS['online']}></span>
                                <p>
                                    {paramUser?.location ? ` ${paramUser?.location?.city} - ${haversineDistance(paramUser?.location?.coordinates?.coordinates, loggedUser?.location?.coordinates?.coordinates)}` : "Just Online"}
                                </p>

                            </div>
                        </div>
                        <div className={ProfileShowCSS['follow-share-content']}>
                            <button className={isFollowing ? `${ProfileShowCSS['filled-follow']}` : ''}
                                    onClick={() => handleFollowClicked()}
                                    disabled={isUploadLoading || isUploadRemoveLoading}>
                                <FontAwesomeIcon icon={faStar}/>
                                {isFollowing ? 'Unfollow' : 'Follow'}
                            </button>
                            <button className='share' onClick={handleShare}>
                                <FontAwesomeIcon icon={faShareNodes}/>
                            </button>
                            <button onClick={handleReport}><FontAwesomeIcon icon={faCircleExclamation}/></button>
                        </div>
                    </div>
                    <div className={ProfileShowCSS['image-box']}>
                        <div
                            onClick={() => navigate(`/dash/user/${paramUser?._id}/user-story`, {
                                state: {
                                    from: `/dash/user/${paramUser?._id}`,
                                    history: state?.from,
                                    id: paramUser?._id
                                }
                            })}
                        >
                            {paramUser ? <ProfileStories dots={false} muted={true} swipe={false}/> : null}
                        </div>
                    </div>
                    <div className={ProfileShowCSS['phone-content']}>
                        <button>
                            <a href={`tel:${phoneNumber}`} style={{color: 'inherit', textDecoration: 'none'}}>
                                <FontAwesomeIcon icon={faPhone}/>
                            </a>
                        </button>
                        <button className={ProfileShowCSS['whatsapp']} onClick={() => openWhatsApp(phoneNumberWhatsapp, predefinedMessage)}>
                            <FontAwesomeIcon icon={faWhatsapp}/>
                        </button>
                    </div>
                </div>
                <BasicTabs
                    loggedUser={loggedUser}
                    paramUser={paramUser}
                    redirectOpened={redirectOpened}
                    setRedirectOpened={setRedirectOpened}
                    reportOpened={reportOpened}
                    setReportOpened={setReportOpened}
                    follower={follower}
                />
                <FullScreenModal
                    fullscreenOpened={fullscreenOpened}
                    closeModal={() => setFullscreenOpened(false)}
                    postUrl={paramUser?.profileImage}
                    postType='image'
                />
            </>
        )
    }
    return content
}
export default ProfileShow