import ContentSectionCSS from '../ContentSection.module.css'
import SmartDisplayOutlinedIcon from '@mui/icons-material/SmartDisplayOutlined'
import ImageOutlinedIcon from '@mui/icons-material/ImageOutlined'
import SquareOutlinedIcon from '@mui/icons-material/SquareOutlined'
import GridOnIcon from '@mui/icons-material/GridOn'
import AddIcon from '@mui/icons-material/Add'
import {useEffect, useState} from 'react'
import AddNewPost from '../Subscription/AddNewPost'
import GridPost from '../Subscription/GridPost'
import PremiumContentFeed from './PremiumContentFeed'
import AddNewPremiumPost from './AddNewPremiumPost'
import GridPremiumPost from "./GridPremiumPost";
import {useSelector} from 'react-redux'
import {selectUserById} from '../../../features/user/usersApiSlice'
const PremiumContent = ({ userId, premmisionLevel =3}) => {

    const [isFeed, setIsFeed] = useState(false)
    const [isImage, setIsImage] = useState(false)
    const [isVideo, setIsVideo] = useState(false)
    const [addNewActive, setAddNewActive] = useState(false)
    const [posts, setPosts] = useState([])

    const loggedUser = useSelector((state) => selectUserById(state, userId))

    useEffect(() => {
        if(loggedUser) setPosts(loggedUser.premiumPosts)
    }, [loggedUser])

    const handleVideoFilter = () => {
        if(!isVideo){
            setIsVideo(true)
            setIsImage(false)
        } else setIsVideo(false)
    }

    const handleImageFilter = () => {
        if(!isImage){
            setIsImage(true)
            setIsVideo(false)
        } else setIsImage(false)
    }

    let content

    if(!addNewActive && loggedUser) {

        const galleryContent = () => {
            const images = [];
            for (let i = posts?.length; i >= 0; i--){
                if(i === posts?.length && premmisionLevel>=3) images.push((<div key={posts?.length - i} onClick={() => setAddNewActive(true)} className={`${ContentSectionCSS['add-new-post']} ${ContentSectionCSS['gallery-item']}`}><AddIcon/></div>))
                images.push(<GridPremiumPost key={posts?.length - i + 1} postId={posts[i-1]} isImage={isImage} isVideo={isVideo}/>)
            }
            return images;
        }

        content = (
            <div className={ContentSectionCSS['gallery-container']}>
                <div className={ContentSectionCSS['gallery-header']}>
                    <div>
                        <SmartDisplayOutlinedIcon onClick={handleVideoFilter} className={`${isVideo ? ContentSectionCSS['active'] : ''}`} />
                        <ImageOutlinedIcon onClick={handleImageFilter} className={`${isImage ? ContentSectionCSS['active'] : ''}`}/>
                        <div>
                            <span>120 videos</span>
                            <span>230 pictures</span>
                        </div>
                    </div>
                    <div>
                        <SquareOutlinedIcon className={`${isFeed ? ContentSectionCSS['active'] : ''}`}
                                            onClick={() => setIsFeed(true)}/>
                        <GridOnIcon className={`${!isFeed ? ContentSectionCSS['active'] : ''}`}
                                    onClick={() => setIsFeed(false)}/>
                    </div>
                </div>
                {isFeed ?
                    <PremiumContentFeed posts={posts} isVideo={isVideo} isImage={isImage}/>
                    :
                    <div className={ContentSectionCSS['gallery-grid']}>
                        {galleryContent()}
                    </div>
                }
            </div>
        )
    }
    else{
        content = <AddNewPremiumPost setAddNewActive={setAddNewActive} loggedUser={loggedUser}/>
    }

    return content
}

export default PremiumContent