import BroadcasterSettingsCSS from './BroadcasterSettings.module.css'
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {
    faFile,
    faHeadset,
    faShare,
    faUnlockKeyhole,
    faPowerOff,
    faTrash,
    faWrench
} from '@fortawesome/free-solid-svg-icons'
import {useLocation, useNavigate} from "react-router-dom"
import {useSendLogoutMutation} from "../../features/auth/authApiSlice"
import {useEffect, useState} from "react"
import PulseLoader from "react-spinners/PulseLoader";
import DeleteUserConfirmation from "./DeleteUserConfirmation";
import ContactSupport from "./ContactSupport";
import TermsOfServiceViewer from "../HomePage/TermsOfServiceViewer";
import PasswordReset from "./PasswordReset";
import useAuth from '../../hooks/useAuth'

const BroadcasterSettings = ({userId, username}) => {

    const navigate = useNavigate()

    const { state } = useLocation()
    const { isManager } = useAuth()

    const [sendLogout, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useSendLogoutMutation()

    const [isConfirmationOpen, setIsConfirmationOpen] = useState(false)
    const [supportOpened, setSupportOpened] = useState(false)
    const [tosIsOpened, setTosIsOpened] = useState(false)
    const [passwordResetOpened, setPasswordResetOpened] = useState(false)
    const [isProvider, setIsProvider] = useState(true)
    const [hasManagerInvite, setHasManagerInvite] = useState(true)
    useEffect(() => {
        if(state?.from === '/dash/user/following') setIsProvider(false)
        else setIsProvider(true)
    }, [state])

    useEffect(() => {
        if (isSuccess) {
            navigate('/dash/login')
        }
    }, [isSuccess, navigate])

    const handleShare = async () => {

        if (navigator.share) {
            try {
                await navigator.share({
                    title: `Profile of ${username}`,
                    url: `https://camnextdoor.ch/dash/user/${userId}`
                });
                console.log('Profile shared successfully');
            } catch (error) {
                console.error('Error sharing profile:', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };

    const handleLogout = async () => {
        try {
            await sendLogout()
        } catch (error) {
            console.error('Logout failed', error)
        }
    }

    const handleInvite = async () => {

        if (navigator.share) {
            try {
                await navigator.share({
                    title: `Join CamNextDoor`,
                    url: 'https://camnextdoor.ch'
                });
                console.log('Profile shared successfully');
            } catch (error) {
                console.error('Error sharing profile:', error);
            }
        } else {
            console.log('Web Share API is not supported in your browser.');
        }
    };
    const handleManagerSettings = () => {
        navigate('/dash/user/settings/manager-settings');
    }

    const handleAcceptInvite = () => {
        setHasManagerInvite(false); // Clear the notification once the invite is accepted
        handleManagerSettings();
    };
    let loadingContent

    if (isLoading) {
        loadingContent = <PulseLoader color={"#F92E85FF"} />
    } else loadingContent = null

    return(
        <div className={BroadcasterSettingsCSS['settings-container']}>
            {!isManager &&
            <button
                className={BroadcasterSettingsCSS['invite']}
                onClick={handleInvite}
            >
                <FontAwesomeIcon icon={faShare} />
                {isProvider ?
                    <span>Invite Friends</span>
                    : <span>Invite Friends</span>}
            </button>
            }
            {!isManager &&
            <div className={BroadcasterSettingsCSS['central-buttons']}>
                {isProvider ?
                    <button
                        onClick={handleManagerSettings}
                        className={BroadcasterSettingsCSS["button-with-bubble"]}
                    >
                        <FontAwesomeIcon icon={faWrench}/>
                        Manager Settings
                        {hasManagerInvite && (
                            <span className={BroadcasterSettingsCSS["notification-bubble"]}>
              !
                </span>
                        )}
                    </button> : null}
                {isProvider ?
                <button onClick={handleShare}>
                    <FontAwesomeIcon icon={faShare} />
                    Share my Profile
                </button> : null}
                <button onClick={() => setSupportOpened(true)}>
                    <FontAwesomeIcon icon={faHeadset} />
                    Contact Support
                </button>
                <button onClick={()=>setTosIsOpened(true)}>
                    <FontAwesomeIcon icon={faFile} />
                    Terms of Service
                </button>
                <button onClick={() => setPasswordResetOpened(true)}>
                    <FontAwesomeIcon icon={faUnlockKeyhole} />
                    Reset Password
                </button>
            </div>
            }
            <div className={BroadcasterSettingsCSS['bottom-buttons']}>
                <button onClick={async () => {
                    await handleLogout()
                    navigate('/dash/login', { state: { loggedout: true } })
                }}>
                    <FontAwesomeIcon icon={faPowerOff} />
                    Log out
                </button>
                <button onClick={() => setIsConfirmationOpen(true)}>
                    <FontAwesomeIcon icon={faTrash} />
                    Delete Account
                </button>
            </div>
            {loadingContent}
            <DeleteUserConfirmation isConfirmationOpen={isConfirmationOpen} closeModal={() => setIsConfirmationOpen(false)} userId={userId} sendLogout={sendLogout}/>
            <ContactSupport userId={userId} supportOpened={supportOpened} closeModal={() => setSupportOpened(false)} />
            <TermsOfServiceViewer tosIsOpened={tosIsOpened} closeModal={()=>setTosIsOpened(false)}/>
            <PasswordReset userId={userId} passwordResetOpened={passwordResetOpened} closeModal={() => setPasswordResetOpened(false)}/>
        </div>
    )
}

export default BroadcasterSettings