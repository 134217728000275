import { Routes, Route } from 'react-router-dom'
import Layout from './components/Layout'
import Public from './components/HomePage/Public'
import Login from './features/auth/Login'
import Signup from './features/auth/Signup'
import DashLayout from './components/DashLayout'
import ProfileShow from './components/ProfileShow/ProfileShow'
import ComingSoon from './components/ComingSoon'
import VerticalStories from './components/VerticalStories/VerticalStories'
import EditUserProfile from './features/user/EditUserProfile'
import useAuth from './hooks/useAuth'
import UserVerification from './components/UserVerification/UserVerification'
import VerifyMe from './components/UserVerification/VerifyMe'
import {useGetUsersQuery} from './features/user/usersApiSlice'
import SearchComponent from './components/SearchComponent/SearchComponent'
import {useState} from 'react'
import ClicksAndImpressions from './components/ClicksAndImpressions/ClicksAndImpressions'
import SearchResults from './components/UserProfile/SearchResults'
import Prefetch from './features/auth/Prefetch'
import PersistLogin from './features/auth/PersistLogin'
import BroadcasterSettings from './components/BroadcasterSettings/BroadcasterSettings'
import EmailVerify from './components/EmailVerify/EmailVerify'
import SearchFilters from './components/SearchComponent/SearchFilters'
import ProfileStories from './components/UserStories/ProfileStories'
import MyFollowers from './components/BroadcasterSettings/MyFollowers'
import CaptureDocument from './components/CaptureDocument/CaptureDocument'
import ProfileImageVerification from './components/EmailVerify/ProfileImageVerification'
import DocumentsVerification from './components/EmailVerify/DocumentsVerification'
import MyStoriesParent from './components/MyStories/MyStoriesParent'
import ContentSectionProvider from './components/ContentSectionProvider/ContentSection'
import ContentSectionStandardUser from './components/ContentSectionStandardUser/ContentSection'
import Navigation from './components/UserProfile/Navigation'
import SinglePostView from './components/ContentSectionProvider/Subscription/SinglePostView'
import SinglePostViewProfileShow from './components/ProfileShow/Subscription/SinglePostView'
import SinglePremiumPostView from './components/ContentSectionProvider/PremiumContent/SinglePremiumPostView'
import RedirectionPage from './components/ContentSectionProvider/RedirectionPage'
import EditPremiumPost from "./components/ContentSectionProvider/PremiumContent/EditPremiumPost"
import EditPost from "./components/ContentSectionProvider/Subscription/EditPost"
import UserRedirect from "./components/UserRedirect/UserRedirect"
import ManagingRequest from './components/EmailVerify/ManagingRequest'
import CreateManagerForm from './components/ManagingAccount/CreateManagerForm'
import ManagerSettings from "./components/BroadcasterSettings/ManagerSettings"
import ManagerTabs from './components/ManagerDash/ManagerTabs'
import ProviderTabs from './components/ManagerDash/Provider/ProviderTabs'
import Statistics from './components/ManagerDash/Statistics'

function App() {

    const { email, isProvider } = useAuth()
    const { data: users } = useGetUsersQuery(undefined, {
        pollingInterval: 60000,
        refetchOnFocus: true,
        refetchOnMountOrArgChange: true
    })

    const [searchResults, setSearchResults] = useState([])
    const [activeIndex, setActiveIndex] = useState(0)

    const searchFilters = SearchFilters()

    const userArray = users ? Object.values(users.entities) : []
    const loggedUser = userArray.find(user => user.email === email)

    const searchFunc = (results) => {
        setSearchResults(results)
    }

    const [showSuccessMessage, setShowSuccessMessage] = useState(false)

  return (
      <Routes>
        <Route path='/' element={<Layout />}>
            <Route index element={<Public />} />
                <Route element={<Prefetch />}>
                    <Route path=':username' element={<UserRedirect/>}/>
                    <Route path='dash' element={<DashLayout email={email} loggedUser={loggedUser}/>}>
                            <Route path='login' element={<Login showSuccessMessage={showSuccessMessage} setShowSuccessMessage={setShowSuccessMessage}/>} />
                        <Route element={<PersistLogin />}>
                            <Route path='verify' element={<UserVerification loggedUser={loggedUser}/>} />
                            <Route path='verify-me' element={<VerifyMe />} />
                            <Route path='documents' element={<CaptureDocument user={loggedUser}/>} />
                            <Route path='signup' element={<Signup setShowSuccessMessage={setShowSuccessMessage}/>} />
                            <Route path='stories' element = {<VerticalStories activeIndex={activeIndex} setActiveIndex={setActiveIndex} />} />
                            <Route path='girl-list' element = {<ManagerTabs loggedUser={loggedUser}/>} />
                            <Route path='statistics' element={<Statistics />} />
                            <Route path='user'>
                                <Route path='following' element={<Navigation loggedUser={loggedUser} searchResults={searchResults} setSearchResults={setSearchResults}/>} />
                                <Route path='my-stories' element={<MyStoriesParent userId={loggedUser?._id} isActive={true}/>} />
                                <Route path='content-section' element={loggedUser ? (isProvider ? <ContentSectionProvider loggedUser={loggedUser} /> : <ContentSectionStandardUser loggedUser={loggedUser} />) : <RedirectionPage /> }/>
                                <Route path='content-section/posts/:postId' element={<SinglePostView loggedUser={loggedUser}/>} />
                                <Route path='content-section/posts/:postId/edit' element={<EditPost/>} />
                                <Route path='content-section/premium-posts/:premiumPostId' element={<SinglePremiumPostView loggedUser={loggedUser}/>} />
                                <Route path='content-section/premium-posts/:premiumPostId/edit' element={<EditPremiumPost/>} />
                                <Route path='edit' element={<EditUserProfile userId={loggedUser?._id}/>} />
                                <Route path='clicks-and-impressions' element={<ClicksAndImpressions loggedUser={loggedUser} />} />
                                <Route path='settings' element={<BroadcasterSettings userId={loggedUser?._id}/>} username={loggedUser?.username} />
                                <Route path='settings/my-followers' element={<MyFollowers userId={loggedUser?._id}/>} />
                                <Route path='settings/manager-settings' element={<ManagerSettings loggedUser={loggedUser}/>} />
                                <Route path='provider'>
                                    <Route path=':providerId/:premmisionLevel' element={<ProviderTabs />} />
                                </Route>
                            </Route>
                            {/*<Route path='webcam' element={<CameraRecording loggedUser={loggedUser}/>} />*/}
                        </Route>
                            <Route path='user/:id' element={<ProfileShow loggedUser={loggedUser}/>}/>
                            <Route path='user/:id/post/:postId' element={<SinglePostViewProfileShow />}/>
                            <Route path='user/:id/user-story' element={<ProfileStories dots={true} muted={false} swipe={true} loggedUser={loggedUser}/>} />
                            <Route path='search' element={<SearchComponent searchFilters={searchFilters} searchFunc={searchFunc}/>} />
                            <Route path='search-results' element={<SearchResults loggedUser={loggedUser} searchResults={searchResults} setSearchResults={setSearchResults}/>} />
                            <Route path='coming-soon' element={<ComingSoon loggedUser={loggedUser}/>} />
                    </Route>
                </Route>
            <Route path='/users/:id/verify/:token' element={<EmailVerify />} />
            <Route path='/users/:id/verify-profile-image/:token/:accepted' element={<ProfileImageVerification />} />
            <Route path='/users/:id/verify-documents/:token/:accepted' element={<DocumentsVerification />} />
            <Route path='/managing-request/:email/:clubName/:address/:accepted' element={<ManagingRequest />} />
            <Route path='/create-manager/:email/:tokenId' element={<CreateManagerForm />} />
        </Route>
      </Routes>
  )
}

export default App